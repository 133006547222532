import React from 'react';
import { motion } from 'framer-motion';
import styles from './Banner.module.scss';

const Punchline = ({ punchline }: { punchline: String }) => {
  const string = Array.from(punchline);

  const containerVariants = {
    before: {},
    after: { transition: { staggerChildren: 0.06 } },
  };

  const letterVariants = {
    before: {
      opacity: 0,
      y: 20,
      transition: {
        type: 'spring',
        damping: 16,
        stiffness: 200,
      },
    },
    after: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        damping: 16,
        stiffness: 200,
      },
    },
  };

  return (
    <motion.div
      //center={ "y" }
      //height={ 26 }
      //width={ "100%" }
      //background={ "" }
      style={{
        // fontFamily: "Montserrat, Work Sans, sans-serif",
        // fontWeight: "bold",
        // letterSpacing: "-0.04em",
        // fontSize: 26,
        // color: "#FFF",
        display: 'flex', // Set the display value to flex
        justifyContent: 'center', // Center all children elements on the x axis
      }}
      variants={containerVariants}
      initial={'before'}
      animate={'after'}
    >
      {string.map((letter, index) => (
        <motion.span
          key={index}
          //width={ "auto" } // Set the width to the width of the letter
          //height={ 26 } // Set the height to the height of the text
          //background={ "" }
          style={{ position: 'relative', width: 'auto' }} // Position elements
          className={styles.blue}
          variants={letterVariants}
        >
          {letter === ' ' ? '\u00A0' : letter}
        </motion.span>
      ))}
    </motion.div>
    // <motion.span
    //   //onAnimationComplete={() => setNextPunchline(punchline)}

    //   initial={{
    //     opacity: 0,
    //     transform: 'translateY(-10px)',
    //   }}
    //   animate={{
    //     opacity: 1,
    //     transform: 'translateY(0px)',
    //   }}
    //   transition={{
    //     duration: 0.5,
    //     ease: 'easeInOut',
    //     loop: Infinity,
    //     repeatDelay: 2.5,
    //     exit: { transform: 'translateY(10px)' },
    //   }}
    //   className={`${styles.blue} d-inline-block`}
    // >
    //   {punchline}
    // </motion.span>
  );
};

export default Punchline;
