import React from "react";
import styles from "./Button.module.scss";

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  size: Number;
  color?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const Button: React.FC<ButtonProps> = ({
  size = 40,
  color = "default",
  children,
  ...rest
}) => {
  return (
    <button className={styles[`button-${size}-${color}`]} {...rest}>
      {children}
      <div className={styles.icon}>
        <i className="fas fa-angle-right float-right"></i>
      </div>
    </button>
  );
};

export default Button;
