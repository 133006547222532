import React from 'react';
import styles from './Features.module.scss';

import Checkbox from 'components/Input/Checkbox';

const Feature = ({ features, register }: { features: string[]; register: any }) => {
  return (
    <div className={styles.featureContainer}>
      <div className="pb-5">
        <div className="mx-auto">
          <div className={styles.featureList}>
            {features.map((feature, i) => (
              <div key={i} className={styles.feature}>
                <div className={styles.featureLeft}>{feature}</div>
                <div className={styles.featureRight}>
                  <Checkbox switchy value={feature} name="usage" ref={register} />
                </div>
              </div>
            ))}
            <input
              type="text"
              className="form-control"
              placeholder="Something else? (optional)"
              name="additionalUsage"
              ref={register}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
