import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => {
  const date = new Date();

  return (
    <div id="apply" className={styles.footer}>
      <div className="container py-3">
        <div className="text-center">© {date.getFullYear()} New Wave Group AB</div>
      </div>
    </div>
  );
};

export default Footer;
