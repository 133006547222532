import React, { useState, useEffect } from 'react';
import styles from './Banner.module.scss';
import Button from 'components/Button/Button';
import { motion } from 'framer-motion';
import Punchline from './Punchline';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Div100vh from 'react-div-100vh';

const Banner = () => {
  const punchlines = ['Fast.', 'At scale.', 'Up to date.', 'Automatically.', 'Consistently.', 'At your command.'];
  const [punchlineIndex, setPunchlineIndex] = useState(0);
  const [renderPunchlines, setRenderPunchlines] = useState(false);

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  useEffect(() => {
    let timeout = punchlineIndex === punchlines.length - 1 ? 5000 : 3000;

    let loopInterval = setInterval(() => {
      setPunchlineIndex((previousVal) => {
        if (previousVal < punchlines.length - 1) {
          return previousVal + 1;
        }
        return 0;
      });
    }, timeout);

    return () => {
      clearInterval(loopInterval);
    };
  }, [punchlineIndex, punchlines.length]);

  return (
    <motion.div initial="hidden" animate="visible" variants={variants}>
      <Div100vh
        className={styles.banner}
        style={{
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <div className={styles.iconScroll}></div>
        <motion.svg viewBox="0 0 1920 1080" className={styles.svg}>
          <rect x="1" y="1" className={styles.st1} width="1919" height="1079" />
          <path
            className={styles.st12}
            d="M130.07,1221.47l342.08-458.42c3.25-4.51,8.8-6.07,12.38-3.48l288.18,151.19c3.58,2.59,3.85,8.34,0.59,12.85
              l-361.62,501.1c-3.25,4.51-8.8,6.07-12.38,3.48l-268.64-193.87C127.08,1231.73,126.81,1225.98,130.07,1221.47z"
          />
          <motion.path
            animate={{
              transform: ['translateY(0px)', 'translateY(5px)', 'translateY(0px)'],
            }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              times: [0, 0.5, 1],
              loop: Infinity,
            }}
            className={styles.st13}
            d="M70.07,1216.47l342.08-458.42c3.25-4.51,8.8-6.07,12.38-3.48l288.18,151.19c3.58,2.59,3.85,8.34,0.59,12.85
            l-361.62,501.1c-3.25,4.51-8.8,6.07-12.38,3.48L70.66,1229.32C67.08,1226.73,66.81,1220.98,70.07,1216.47z"
          />
          <motion.path
            animate={{
              transform: ['translateY(0px)', 'translateY(5px)', 'translateY(0px)'],
            }}
            transition={{
              duration: 2.1,
              ease: 'easeInOut',
              times: [0, 0.5, 1],
              loop: Infinity,
            }}
            className={styles.st14}
            d="M101.83,539.37l-476.81-344.09c-3.58-2.59-4.39-7.59-1.81-11.17l232.77-322.56
            c2.59-3.58,7.59-4.39,11.17-1.81l476.81,344.09c3.58,2.59,4.39,7.59,1.81,11.17L113,537.57
            C110.42,541.15,105.41,541.96,101.83,539.37z"
          />
          <path
            className={styles.st14}
            d="M85.83,649.37l-476.81-344.09c-3.58-2.59-4.39-7.59-1.81-11.17l232.77-322.56c2.59-3.58,7.59-4.39,11.17-1.81
            l476.81,344.09c3.58,2.59,4.39,7.59,1.81,11.17L97,647.57C94.42,651.15,89.41,651.96,85.83,649.37z"
          />
          <motion.path
            animate={{
              transform: ['translateY(0px)', 'translateY(5px)', 'translateY(0px)'],
            }}
            transition={{
              duration: 2.2,
              ease: 'easeInOut',
              times: [0, 0.5, 1],
              loop: Infinity,
            }}
            className={styles.st15}
            d="M2025.51,923.87l-595.96-327.19c-4.48-2.46-6.38-7.59-4.26-11.46l258.96-311.68
            c2.13-3.87,7.48-5.02,11.96-2.56l528.48,290.11c4.48,2.46,6.38,7.59,4.26,11.46l-191.47,348.76
            C2035.34,925.18,2029.99,926.33,2025.51,923.87z"
          />
          <path
            className={styles.st15}
            d="M2025.51,954.87l-595.96-327.19c-4.48-2.46-6.38-7.59-4.26-11.46l258.96-311.68
            c2.13-3.87,7.48-5.02,11.96-2.56l528.48,290.11c4.48,2.46,6.38,7.59,4.26,11.46l-191.47,348.76
            C2035.34,956.18,2029.99,957.33,2025.51,954.87z"
          />
          <motion.circle
            animate={{
              transform: ['translateY(0px)', 'translateY(5px)', 'translateY(0px)'],
            }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              times: [0, 0.5, 1],
              loop: Infinity,
            }}
            className={styles.st12}
            cx="161.58"
            cy="258.88"
            r="21"
          />
          <motion.circle
            animate={{
              transform: ['translateY(0px)', 'translateY(5px)', 'translateY(0px)'],
            }}
            transition={{
              duration: 2.2,
              ease: 'easeInOut',
              times: [0, 0.5, 1],
              loop: Infinity,
            }}
            className={styles.st12}
            cx="582.58"
            cy="751.88"
            r="15"
          />
          <motion.circle
            animate={{
              transform: ['translateY(0px)', 'translateY(5px)', 'translateY(0px)'],
            }}
            transition={{
              duration: 1.9,
              ease: 'easeInOut',
              times: [0, 0.5, 1],
              loop: Infinity,
            }}
            className={styles.st16}
            cx="487.58"
            cy="344.88"
            r="15"
          />
          <motion.circle
            animate={{
              transform: ['translateY(0px)', 'translateY(5px)', 'translateY(0px)'],
            }}
            transition={{
              duration: 2.0,
              ease: 'easeInOut',
              times: [0, 0.5, 1],
              loop: Infinity,
            }}
            className={styles.st16}
            cx="1520.08"
            cy="767.38"
            r="15"
          />
          <motion.circle
            animate={{
              transform: ['translateY(0px)', 'translateY(5px)', 'translateY(0px)'],
            }}
            transition={{
              duration: 2.4,
              ease: 'easeInOut',
              times: [0, 0.5, 1],
              loop: Infinity,
            }}
            className={styles.st16}
            cx="1520.58"
            cy="229.88"
            r="19.5"
          />
          <motion.circle
            animate={{
              transform: ['translateY(0px)', 'translateY(5px)', 'translateY(0px)'],
            }}
            transition={{
              duration: 1.7,
              ease: 'easeInOut',
              times: [0, 0.5, 1],
              loop: Infinity,
            }}
            className={styles.st17}
            cx="1699.08"
            cy="373.38"
            r="21"
          />
          <motion.circle
            animate={{
              transform: ['translateY(0px)', 'translateY(5px)', 'translateY(0px)'],
            }}
            transition={{
              duration: 2.3,
              ease: 'easeInOut',
              times: [0, 0.5, 1],
              loop: Infinity,
            }}
            className={styles.st15}
            cx="1795.58"
            cy="722.88"
            r="15"
          />
          <motion.circle
            animate={{
              transform: ['translateY(0px)', 'translateY(5px)', 'translateY(0px)'],
            }}
            transition={{
              duration: 1.8,
              ease: 'easeInOut',
              times: [0, 0.5, 1],
              loop: Infinity,
            }}
            className={styles.st17}
            cx="254.08"
            cy="533.38"
            r="15"
          />
          <path className={styles.st10} d="M1.08,1081.38c750.66-145.47,1389.99-112.13,1920,0" />
        </motion.svg>
        <div className={styles.content}>
          <h1 className="mt-3 px-3">
            <motion.span
              className="d-inline-block"
              initial={{
                opacity: 0,
                transform: 'translateY(30px)',
              }}
              animate={{
                opacity: 1,
                transform: 'translateY(0px)',
              }}
              transition={{
                duration: 0.8,
                ease: 'easeInOut',
              }}
            >
              Data delivered,
            </motion.span>{' '}
            <br />{' '}
            <motion.span
              onAnimationComplete={() => setRenderPunchlines(true)}
              className="d-inline-block"
              initial={{
                opacity: 0,
                transform: 'translateY(30px)',
              }}
              animate={{
                opacity: 1,
                transform: 'translateY(0px)',
              }}
              transition={{
                duration: 0.8,
                ease: 'easeInOut',
                delay: 0.5,
              }}
            >
              as requested.
            </motion.span>
            <br />
            {renderPunchlines && <Punchline key={punchlineIndex} punchline={punchlines[punchlineIndex]} />}
          </h1>
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 1.5,
              ease: 'easeInOut',
              delay: 1.5,
            }}
          >
            <p className="mt-0 mb-4 mb-md-5 px-3 mx-auto">
              NWG Gateway is a unified data layer that enables applications to interact with New Wave Group's internal
              back-end services and data stores through a single endpoint, simplifying the implementation of digital
              product- and sales driven solutions - efficiently and at scale.
            </p>
            <AnchorLink href="#apply">
              <Button size={50} color="default">
                Get access
              </Button>
            </AnchorLink>
          </motion.div>
        </div>
      </Div100vh>
    </motion.div>
  );
};

export default Banner;
