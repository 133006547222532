import React from 'react';
import styles from './Header.module.scss';
import Button from 'components/Button/Button';

const Header = () => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>NWG Gateway</div>
      <div className={styles.actions}>
        <a href="https://portal.gateway.nwg.se" target="_blank" rel="noopener noreferrer">
          <Button size={50} color="default">
            Portal
          </Button>
        </a>
      </div>
    </div>
  );
};

export default Header;
