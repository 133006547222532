import React from 'react';
import styles from './Steps.module.scss';

const Steps = () => {
  return (
    <div className={styles.stepsContainer}>
      <div className="container">
        <div className={styles.stepsRow}>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className={styles.step}>
                <h4>1</h4>{' '}
                <div className={styles.text}>Apply and get access to create your token in NWG Gateway Portal.</div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className={styles.step}>
                <h4>2</h4>{' '}
                <div className={styles.text}>
                  Compose and shape your queries in the Playground to get data that fits your platform.
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className={styles.step}>
                <h4>3</h4>{' '}
                <div className={styles.text}>Setup a GraphQL or REST client and begin executing your queries.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
