import React from 'react';
import styles from './Checkbox.module.scss';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  value: string;
  checked?: boolean;
  switchy?: boolean;
  ref?: any;
};

const Checkbox = React.forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {
  const { name, label, switchy, ...rest } = props;

  return (
    <div className={styles.checkbox}>
      <label>
        <input type="checkbox" className={switchy ? styles.switch : ''} name={name} ref={ref} {...rest} />
        {label && label}
      </label>
    </div>
  );
});

export default Checkbox;
