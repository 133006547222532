import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { motion } from 'framer-motion';

// Components
import Header from 'components/Header/Header';
import Banner from 'components/Banner/Banner';
import Showcase from 'components/Showcase/Showcase';
import Footer from 'components/Footer/Footer';
import Steps from 'components/Steps/Steps';
import Form from 'components/Form/Form';

// Images
import imgGateway from '../../media/gateway.svg';
import imgApps from '../../media/apps.svg';
import imgSpeed from '../../media/speed.svg';

// Global styles
import 'styles/app.scss';

function App() {
  return (
    <>
      <Header />
      <Banner />
      <VisibilitySensor key={1} partialVisibility>
        {({ isVisible }) => (
          <Showcase
            title="One endpoint"
            textIntro="NWG Gateway"
            text={
              <motion.span
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: isVisible ? 1 : 0,
                }}
                transition={{
                  duration: 1,
                  ease: 'easeInOut',
                }}
              >
                is a unified data layer that enables applications to interact with our internal back-end services and
                data stores. It decouples our services from your apps so that you can develop each independently of the
                other in any language or platform.
              </motion.span>
            }
            image={imgGateway}
          />
        )}
      </VisibilitySensor>
      <VisibilitySensor key={2} partialVisibility>
        {({ isVisible }) => (
          <Showcase
            title="Ease of use"
            textIntro="Spend less time on adjusting and integrating data models"
            text={
              <motion.span
                initial={{
                  opacity: 0,
                  transform: 'translateX(-15px)',
                }}
                animate={{
                  opacity: isVisible ? 1 : 0,
                  transform: isVisible ? 'translateX(0px)' : 'translateX(-15px)',
                }}
                transition={{
                  duration: 1,
                  ease: 'easeInOut',
                }}
              >
                and instead focus more on user experiences. Getting what you need is easy: browse everything that's
                available in the API in our Playground and compose queries to get the data in the shape you need for any
                type of platform.
              </motion.span>
            }
            swapOrientation
            image={imgApps}
          />
        )}
      </VisibilitySensor>
      <VisibilitySensor key={3} partialVisibility>
        {({ isVisible }) => (
          <Showcase
            title="Speed"
            textIntro="Fast and up to date."
            text={
              <motion.span
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: isVisible ? 1 : 0,
                }}
                transition={{
                  duration: 1,
                  ease: 'easeInOut',
                }}
              >
                Our services are built on modern technology making it possible to fetch entire product assortments in
                seconds. Everything is synchronized around the clock so that you can expect up to date results on every
                execution.
              </motion.span>
            }
            component={
              <div>
                <img style={{ width: 300 }} src={imgSpeed} alt="speed" />
              </div>
            }
          />
        )}
      </VisibilitySensor>
      <VisibilitySensor key={4} partialVisibility>
        {({ isVisible }) => (
          <Showcase
            title="Getting started"
            textIntro={
              <>
                Products, Commerce and more.
                <br />
                <span className="text-blue">All behind one endpoint.</span>
              </>
            }
            text={
              <>
                <motion.span
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: isVisible ? 1 : 0,
                  }}
                  transition={{
                    duration: 1,
                    ease: 'easeInOut',
                  }}
                >
                  <br />
                  <br />
                  We've made it easy to get up and running quickly. Apply below to gain access to the Gateway Portal and
                  start exploring all queries in the Playground, try out the Product export tool, or set up your
                  application tokens to instantly begin fetching production ready data into your platform.
                </motion.span>
              </>
            }
            center
            backgroundColor="#f2f2f2"
            component={
              <>
                <Steps />
                {/* <Features
                  features={[
                    'Product search',
                    'Product data',
                    'Product stock',
                    'Order placement',
                    'Order status',
                    'Tracking codes',
                    'Retail prices',
                    'File exports',
                  ]}
                /> */}

                <Form />
              </>
            }
          />
        )}
      </VisibilitySensor>
      <Footer />
    </>
  );
}

export default App;
