import React from 'react';
import styles from './Showcase.module.scss';

type Props = {
  swapOrientation?: boolean;
  center?: boolean;
  backgroundColor?: string;
  title: string;
  textIntro?: string | JSX.Element;
  text: string | JSX.Element;
  component?: JSX.Element;
  image?: string;
};

const Showcase: React.FC<Props> = ({
  swapOrientation,
  center,
  backgroundColor,
  title,
  textIntro,
  text,
  component,
  image,
}) => {
  return (
    <div className={styles.showcase} style={{ ...styles, backgroundColor: backgroundColor }}>
      <div className={styles.content}>
        <div className={`container ${center && 'text-center'} px-5 py-5`}>
          <div className="row">
            {image && (
              <div className={`col-12 col-lg-10 mb-5 ${swapOrientation && 'ml-auto'}`}>
                <div className={styles.image}>
                  <img src={image} alt="" />
                </div>
              </div>
            )}
            <div className={`col-12 col-lg-10 ${swapOrientation && 'ml-auto'} ${center && 'mx-auto'}`}>
              <div className={styles.title}>{title}</div>
              <div className={styles.text}>
                <span className={styles.textIntro}>{textIntro}</span> {text}
              </div>
            </div>
            {component &&
              (center ? (
                <div className="col-12">
                  <div className="my-3">{component}</div>
                </div>
              ) : (
                <div className="col-12 col-lg-2">
                  <div className="my-3">{component}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Showcase;
