import React, { useState } from 'react';
import styles from './Form.module.scss';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { ValueType, OptionTypeBase } from 'react-select/src/types';
import Reaptcha from 'reaptcha';

import Button from 'components/Button/Button';
import Features from 'components/Features/Features';

type Inputs = {
  company: string;
  contactPerson: string;
  phone: string;
  email: string;
  customerOf: string[];
  usage: string[];
  additionalUsage: string;
};

const Form = () => {
  const { register, handleSubmit, errors, setValue } = useForm<Inputs>();
  const [formResponse, setFormResponse] = useState<string>('');
  const [captcha, setCaptcha] = useState({ verified: false, ready: false });
  const [warning, setWarning] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState(false);

  const onVerify = async (recaptchaResponse: any) => {
    await fetch('/api/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ recaptchaResponse: recaptchaResponse }),
    })
      .then((response) => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error('Bad response from server');
        }
        return response.json();
      })
      .then((returnedResponse) => {
        if (!returnedResponse.success) {
          setWarning('Invalid form input. Please reload and try again.');
        }

        setCaptcha((captcha) => ({ ...captcha, verified: true }));
        setWarning(null);
      })
      .catch((error) => {
        setWarning('Something went wrong. Please reload and try again.');
        console.log(error);
      });
  };

  const onLoad = () => {
    setCaptcha((captcha) => ({ ...captcha, ready: true }));
  };

  const onSubmit = async (data: Inputs) => {
    if (!captcha.verified) {
      setWarning('Please fill in the captcha.');
      return;
    }

    setSubmitted(true);
    setWarning(null);

    await fetch('/api/enroll', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data }),
    })
      .then((response) => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error('Bad response from server');
        }
        return response;
      })
      .then(async (returnedResponse) => {
        const body = await returnedResponse.text();

        setFormResponse(body);
      })
      .catch((error) => {
        setWarning('Something went wrong. Please reload and try again.');
        setSubmitted(false);
        console.log(error);
      });
  };

  const companyOptions = [
    { value: 'Craft of Scandinavia', label: 'Craft of Scandinavia' },
    { value: 'D&J Frantextil', label: 'D&J Frantextil' },
    { value: 'Jobman', label: 'Jobman' },
    { value: 'New Wave Austria', label: 'New Wave Austria' },
    { value: 'New Wave Denmark', label: 'New Wave Denmark' },
    { value: 'New Wave Finland', label: 'New Wave Finland' },
    { value: 'New Wave France', label: 'New Wave France' },
    { value: 'New Wave Germany', label: 'New Wave Germany' },
    { value: 'New Wave Italy', label: 'New Wave Italy' },
    { value: 'New Wave Mode', label: 'New Wave Mode' },
    { value: 'New Wave Norway', label: 'New Wave Norway' },
    { value: 'New Wave Switzerland', label: 'New Wave Switzerland' },
    { value: 'New Wave Textiles', label: 'New Wave Textiles' },
    { value: 'New Wave Profile', label: 'New Wave Profile' },
    { value: 'Orrefors Kosta Boda', label: 'Orrefors Kosta Boda' },
    { value: 'Pax of Scandinavia', label: 'Pax of Scandinavia' },
    { value: 'Projob', label: 'Projob' },
    { value: 'Sagaform', label: 'Sagaform' },
    { value: 'Seger', label: 'Seger' },
    { value: 'Texet Benelux', label: 'Texet Benelux' },
    { value: 'Texet France', label: 'Texet France' },
    { value: 'Texet Sweden', label: 'Texet Sweden' },
    { value: 'Textilgrossisten Hefa', label: 'Textilgrossisten Hefa' },
    { value: 'United Brands of Scandinavia', label: 'United Brands of Scandinavia' },
  ];

  /**
   * Manually register fields in react-hook-form
   * Fields are also required and will need a field validation trigger in setValue()
   * E.g setValue('name', value, true) where 'true' triggers a field validation
   */
  register({ name: 'customerOf' }, { required: true });

  const handleCompanySelectChange = (values: ValueType<OptionTypeBase, true>) => {
    if (!values) {
      setValue('customerOf', null, { shouldValidate: true });
    } else {
      const companyNames = values.map((val: OptionTypeBase) => val.value);
      return setValue('customerOf', companyNames, { shouldValidate: true });
    }
  };

  return (
    <div className={styles.formContainer}>
      <div className="container">
        <h2 className="text-big text-blue text-center mb-4">Apply for access</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            <div className="col-md-6 pr-0 pr-md-3">
              <h2 className="text-light">Im going to use NWG Gateway for:</h2>
              <Features
                features={[
                  'Product data',
                  'Product search',
                  'Article stock',
                  'Order placement',
                  'Order status updates',
                  'Tracking codes',
                  'Retail prices',
                  'Article file exports',
                ]}
                register={register}
              />
            </div>
            <div className="col-md-6 pl-0 pl-md-3">
              <h2 className="text-light">Contact details:</h2>
              <div className="form-group">
                <label>Company</label>
                <input type="text" className="form-control" name="company" ref={register({ required: true })} />
                {errors.company && <div className="d-block invalid-feedback">Please provide a company name.</div>}
              </div>
              <div className="form-group">
                <label>Contact person</label>
                <input type="text" className="form-control" name="contactPerson" ref={register({ required: true })} />
                {errors.contactPerson && (
                  <div className="d-block invalid-feedback">Please provide a contact person.</div>
                )}
              </div>
              <div className="form-group">
                <label>Phone (optional)</label>
                <input type="text" className="form-control" name="phone" ref={register} />
              </div>
              <div className="form-group">
                <label>E-mail</label>
                <input type="email" className="form-control" name="email" ref={register({ required: true })} />
                {errors.email && <div className="d-block invalid-feedback">Please provide an email address.</div>}
              </div>
              <div className="form-group">
                <label>I'm a customer of:</label>
                <Select
                  isMulti
                  name="customerOf"
                  options={companyOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(value) => handleCompanySelectChange(value)}
                />
                {errors.customerOf && <div className="d-block invalid-feedback">Please select a company.</div>}
              </div>
            </div>
          </div>
          <div className="text-center">
            {formResponse && (
              <p>
                <i className="fas fa-check text-success"></i> {formResponse}
              </p>
            )}

            <Reaptcha
              sitekey="6Lew7fQZAAAAAFZ5iuNAs9gDePukAljiQM4FLdKD"
              onLoad={onLoad}
              onVerify={onVerify}
              className="d-inline-block"
            />
            <p className="text-danger">{warning && <>{warning}</>}</p>
            <Button type="submit" disabled={submitted || !captcha.ready} size={50} color="blue">
              Send
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
